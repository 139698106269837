define("discourse/plugins/discourse-ai/initializers/admin-reports", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-ai/discourse/components/admin-report-emotion"], function (_exports, _pluginApi, _adminReportEmotion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-ai-admin-reports",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser || !currentUser.admin) {
        return;
      }
      (0, _pluginApi.withPluginApi)("2.0.1", api => {
        api.registerReportModeComponent("emotion", _adminReportEmotion.default);
      });
    }
  };
});